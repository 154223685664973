@use '../../../styles/vars';

.singleAlbum {
    width     : 95%;
    margin    : 0 auto;
    // flex-grow : 1;
    overflow-x: hidden;

    .menuSingleAlbumStart {
        font-weight: bold;
        margin-bottom: 30px;
    }

    
    .album-header {
        .album_actions {
            // display   : flex;
            // z-index   : 1;
            position  : fixed;
            top       : 85px;
            right     : -200px;
            transform : translate(0px);
            transition: transform 0.5s;

            .action_icon {
                height          : 30px;
                width           : max-content;
                padding         : 0 10px;
                border-radius   : 15px;
                border          : 1px solid vars.$primary;
                margin-right    : 15px;
                color           : white;
                display         : flex;
                justify-content : center;
                align-items     : center;
                background-color: vars.$primary;

                &:hover {
                    cursor: pointer;
                }
            }

            .action_icon--open {
                width        : 30px;
                border-radius: 50%;
                transform    : rotate(180deg);
                transition   : transform 0.5s
            }
        }

        .album_actions--open {
            transform : translate(-210px);
            transition: transform 0.5s;
        }

        .actions_list {
            width: 200px;

            .accordion-item {
                border-radius: unset;
                border-top   : none;
                border-bottom: none;

                button {
                    line-height: 1.5rem;

                    &::after {
                        display: none;
                    }

                    &:focus {
                        border-color: unset;
                        box-shadow  : unset;
                    }
                }

                .accordion-body {
                    padding: 0;

                    .list-group-item {
                        border   : none;
                        font-size: 0.9rem;
                    }
                }
            }
        }

        .album-title {
            margin          : 0 auto 0 10px;
            position        : fixed;
            top             : 70px;
            left            : 0px;
            width           : 100%;
            z-index         : 1;
            background-color: #fff;

            .returnToAlbumList {
                padding-right: 20px;
                font-size: 1rem;
            }
        }

        @media only screen and (min-width: 768px) {
            .album-title {
                margin-left: 275px;
                width      : calc(100% - 275px);
            }
        }

        .title,
        .title-input {
            display        : flex;
            justify-content: space-between;
            align-items    : center;
        }

        .title {
            .edit-button {
                background-color: transparent;
                border          : none;
                margin-top      : 0px;
                margin-left     : 10px;
                color           : vars.$primary;
            }
        }

        .title-input {
            margin-top   : 10px;
            margin-bottom: 8px;

            .buttons {
                display: flex;
            }

            @media(max-width: 525px) {
                flex-direction: column;
                align-items   : flex-start;

                label {
                    margin-left: 10px;
                }

                .buttons {
                    margin-top: 10px;
                }
            }

            input {
                font-size    : 1.2rem;
                border-radius: 5px;
                border       : 1px solid vars.$primary;
                padding-left : 10px;
            }

            .btn-primary,
            .btn-secondary {
                display        : flex;
                align-items    : center;
                justify-content: center;
                height         : 30px;
                margin-left    : 10px;
            }
        }

        .delete-button {
            height    : 80%;
            align-self: center;
        }
    }
}

#shareAlbum-modal .modal-content {
    @media (max-width: 1023px) {
        width: 100% !important;
    }

    @media(min-width: 1024px) {
        width : 80% !important;
        margin: 0 auto;
    }

    .modal-header {
        border-bottom : 0;
        padding       : 0;
        flex-direction: row-reverse;
    }

    .modal-body {
        padding-top: 0;

        #shareAlbum {
            display: flex;

            @media (max-width: 525px) {
                flex-direction: column;
            }

            .shareAlbum-field {
                flex-grow: 1;
            }

            .email-already-shared {
                color    : red;
                font-size: 0.7rem;
            }

            .btn-primary {
                height       : 80%;
                // align-self: flex-end;
                margin-left  : 10px;
                margin-top   : 30px;

                @media (max-width: 525px) {
                    width : 40%;
                    margin: 10px 0 0;
                }
            }
        }
    }

    .suggested-shares {
        border    : 1px solid lightgray;
        border-top: 0;
        list-style: none;
        padding   : 0;

        li {
            font-size   : 0.9rem;
            padding-left: 10px;

            &:hover {
                background-color: vars.$primary;
                color           : white;
            }
        }
    }

    .sharing-contacts {
        margin-top: 10px;
    }

    .sharing-contacts-list {
        margin-top    : 5px;
        display       : flex;
        flex-direction: column;
        padding-left  : 0;
        border        : 0.5px solid lightgrey;
        border-radius : 15px;

        .contact-item {
            display        : flex;
            padding-left   : 10px;
            text-decoration: none;
            justify-content: space-between;
            align-items    : center;

            @media(max-width: 525px) {
                padding     : 5px;
                padding-left: 10px;
            }

            &:first-of-type {
                border-radius: 15px 15px 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 15px 15px;
            }

            &:nth-child(even) {
                background-color: rgba(vars.$primary, 0.2);
            }
        }
    }

    .public-share {
        color      : vars.$primary;
        border-top : 2px solid vars.$primary;
        margin-top : 25px;
        padding-top: 5px;
    }

    .url-public-share {
        span {
            font-weight: 600;
        }

        .url {
            display        : flex;
            align-items    : center;
            justify-content: space-between;

            a {
                color        : black;
                word-break   : break-all;
                padding-right: 10px;
            }
        }
    }

    .deleteShare-button {
        background-color: transparent;
        border          : none;
        color           : vars.$primary;
    }
}

.shareAlbum-modal--fade {
    opacity: 0.3;
}

.shareAlbum-backdrop--fade {
    opacity: 0.7 !important;
}