@use '../../styles/vars';

.container {
    margin-top: 15px;
    flex-grow : 1;
    width     : 70% !important;

    @media (max-width: 424px) {
        width: 90% !important;
    }

    label {
        width: 100%;
    }

    .btn {
        margin: 0 0.5%;
    }

    .prices {
        margin-top : 30px;
        font-weight: 600;

        .prices-cards {
            width  : 100%;
            display: flex;

            .card {
                height   : fit-content;
                flex-grow: 1;
                margin   : 10px;

                .card-title {
                    margin-top : 10px;
                    text-align : center;
                    color      : vars.$primary;
                    font-size  : 1.5rem;
                    font-weight: 700;
                }

                .list-group {
                    border: none;
                    width : 95%;
                    margin: 10px auto 20px;

                    .list-group-item {
                        display: flex;
                        border : none;
                        padding: 0;

                        svg {
                            margin-right: 5px;
                            position    : relative;
                            top         : 3px;
                        }

                        p {
                            margin: 0;

                            &.not-included {
                                text-decoration: line-through;
                                font-weight    : 300;
                            }

                            &.unavailable {
                                font-weight: 300;
                            }
                        }
                    }
                }

                .card-footer {
                    border-top: 1px solid rgba(0, 0, 0, 0.125);
                    text-align: center;

                    .price-offer {
                        font-size: 2rem;
                        margin   : 0;
                    }

                    .commitment {
                        font-weight: 300;
                        font-style : italic;
                    }
                }
            }
        }
    }

    .no-account {
        margin-left: 10px;
    }
}

#changingPassword-modal {
    @media(min-width: 768px) {
        width: 35%;
    }

    .buttons {
        margin-top: 10px;
        text-align: right;

        &>* {
            margin-left: 10px;
        }
    }
}

#login {
    position: relative;

    #show-password {
        position        : absolute;
        right           : 5px;
        bottom          : 55px;
        border          : none;
        background-color: transparent;
    }

    .buttons {
        margin: 10px 10px 0 0;
    }
}