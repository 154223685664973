@use './styles/vars';

body {
    height   : 100vh;
    max-width: 100vw;
}

#root {
    height: 100%;
    width : 100%;
}