@use "../../styles/vars";

.header {
    background-color: white;
    z-index         : 3 !important;

    .navbar-header {
        background-color: white;
        border-bottom   : solid 4px vars.$primary;
        height          : 70px;

        .logoAnoukiss {
            max-width: 90%;
        }

        .titleLogo {
            // display: inline-block;
            justify-self: flex-start;
            margin-left : 10px;
            font-size   : 1.5em;
            font-family : "Poiret One", cursive;
            color       : #999;
        }

        .navbar-toggler.collapsed {
            border: none !important;

            &:focus {
                outline   : none;
                box-shadow: unset !important;
            }

            span {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="#{vars.$primary}" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/></svg>') !important;
            }
        }

        .navbar-collapse {
            text-align: right;

            .navbar-nav {
                .btn {
                    text-decoration: none;
                }
            }
        }

        .show {

            .btn-primary,
            .btn-link {
                width: 130px;
            }
        }

        .FontAwesomeFaGlobe,
        .FontAwesomeFaUser {
            color: vars.$primary;
        }
    }

    #langages-dropdown {
        inset: unset !important;
        top  : 50px !important;
        left : -50% !important;
    }

    .firstname {
        margin-left: 5px;
        color      : vars.$primary;
    }

    #user-dropdown {
        inset   : unset !important;
        width   : max-content;
        position: absolute;
        right   : 0px !important;
    }
}