@use '../../styles/vars';

.offCanvas-buttons {
    width          : 100%;
    display        : flex;
    justify-content: space-between;

    .play-slides {
        color: vars.$primary;
    }

    .other-functions-buttons {
        display: flex;

        .likes,
        .comments {
            position: relative;

            #likes-counter,
            #comments-counter {
                position        : absolute;
                top             : 25px;
                right           : 12px;
                background-color: transparent !important;
                border-radius   : 50%;
                font-size       : 0.7rem;
                color           : vars.$primary;
            }
        }

        .add,
        .delete,
        .likeInSlide,
        .change-answerkiss,
        .comments-dropdown {
            margin-left     : 5px;
            background-color: transparent;
            border          : none;
            color           : vars.$primary;

            .likeInSlide-icon {
                color: gray;

                &.alight {
                    color: vars.$primary;
                }
            }
        }
    }
}