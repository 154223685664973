@use './styles/vars';

@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

.app {
    position       : relative;
    min-height     : 100%;
    max-width      : 100%;
}

button:focus,
input:focus {
    box-shadow: none !important;
}