@use '../../styles/vars';

.lightbox {
    width           : 100%;
    height          : 100%;
    background-color: rgba(black, 0.8);
    position        : fixed;
    top             : 0;
    left            : 0;
    z-index         : 10;

    .slides {
        margin        : 0;
        display       : flex;
        flex-direction: column;

        .close-lightbox {
            width   : max-content;
            position: fixed;
            top     : 0;
            left    : 0;
            z-index : 2;

            .close-button {
                color    : white;
                padding  : 10px;
                font-size: 1.5rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .carousel {
            flex-grow      : 1;
            position       : relative;
            max-height     : 100vh;
            max-width      : 100vw;
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            .carousel-control-prev,
            .carousel-control-next {
                opacity    : 0.8;
                display    : flex;
                align-items: center;
                position   : absolute;
            }

            .carousel-control-prev {
                margin-left    : 10px;
                justify-content: flex-start;
                order          : 1;
            }

            .carousel-control-next {
                margin-right   : 10px;
                justify-content: flex-end;
                order          : 3;
            }

            .prevIcon,
            .nextIcon {
                font-size: 2rem;
            }

            .carousel-inner {
                display        : flex;
                justify-content: center;
                align-items    : center;
                order          : 2;
            }

            .carousel-item {
                margin: 0 !important;

                .slide {
                    display        : flex;
                    flex-direction : column;
                    justify-content: center;
                    align-items    : center;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.lightbox-progress {
    position                 : fixed;
    top                      : 0;
    transform-origin         : left;
    height                   : 3px !important;
    border-radius            : unset !important;
    animation-name           : progress !important;
    animation-duration       : 5s;
    animation-timing-function: linear;
    background-color         : white;
}

@keyframes progress {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.modal-dialog {
    @media (min-width: 576px) {
        margin: 20rem auto !important;
    }
}

#updateAlbum-modal {
    @media (max-width: 424px) {
        width: 85%;
    }

    @media (min-width: 425px) {
        width: 65%;
    }

    @media (min-width: 768px) {
        width: 55%;
    }

    @media (min-width: 992px) {
        width: 45%;
    }

    @media (min-width: 1200px) {
        width: 35%;
    }

    #update-album {
        label {
            display       : flex;
            flex-direction: column;

            span {
                margin-bottom: 5px;
            }

            #albums-list {
                padding         : 3px;
                border          : 1px solid vars.$primary;
                border-radius   : 15px;
                color           : white;
                background-color: vars.$primary;
            }
        }
    }
}


.lightbox-tooltips {
    .tooltip-inner {
        background-color: vars.$primary !important;
    }

    .tooltip-arrow:before {
        border-bottom-color: vars.$primary !important;
    }
}