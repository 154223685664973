.footer {
    position       : fixed;
    bottom         : 0px;
    width          : 100%;
    height         : 35px;
    color          : #ffffff;
    padding        : 0.5%;
    display        : flex;
    justify-content: center;
    align-items    : center;
    font-size      : 0.7rem;
    z-index        : 1;

    a {
        margin-right   : 3%;
        color          : #ffffff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color          : #ffffff;
        }
    }
}