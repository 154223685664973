@use '../../styles/vars';

.titreAide {
    color: vars.$primary;
    cursor: pointer;
}

.aideVisible {
    display: block;
}

.aideNonVisible {
    display: none;
}

.questionExemple {
    cursor: pointer;
}
.questionExemple:hover {
    text-decoration: underline;
}


#addAnswerkissModal .row {
    margin-bottom: 0;
}