@use '../../../styles/vars';

#offCanvas-comments {
    z-index         : 100;
    background-color: white !important;

    .offcanvas-header {
        // background-color: vars.$primary;
        // color: white;
        border-bottom: 3px solid vars.$primary;
        padding      : 10px;
        font-size    : 1.5rem;
    }

    .offcanvas-body {
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        padding        : 0;
        overflow-y     : hidden;

        .offcanvas-title {
            color          : vars.$primary;
            padding        : 10px;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
        }

        .comments-list {
            // max-height: 100px;
            overflow-y     : scroll;
            padding        : 10px 10px 0 10px;
            list-style-type: none;

            .comment-item {
                display        : flex;
                width          : 100%;
                justify-content: space-between;

                .comment-content {
                    margin     : 10px 0;
                    font-size  : 0.9rem;
                    line-height: 1rem;

                    .comment-owner {
                        font-weight: 700;
                        color      : vars.$primary
                    }
                }

                .delete-comment {
                    background-color: transparent;
                    border          : none;

                    .delete-comment-icon {
                        color    : gray;
                        font-size: 1.1rem;
                    }
                }
            }
        }

        .send-comment {
            position     : relative;
            width        : 100%;
            align-self   : center;
            margin-top   : auto !important;
            margin-bottom: 10px;
            border-top   : 2px solid vars.$primary;

            .add-comment {
                color       : vars.$primary;
                font-weight : 700;
                padding-left: 10px;
            }

            .react-emoji {
                height: 110px;

                .react-input-emoji--container {
                    height: 100%;
                }
            }

            .send-button {
                text-align: center;
                padding   : 10px 10px 0 10px;

                .btn.btn-primary {
                    width        : 100%;
                    border-radius: 20px;
                }
            }
        }
    }
}

#popover {
    display       : flex;
    flex-direction: column;

    svg {
        margin-left: auto;
        padding    : 5px;
    }

    .popover-body {
        padding    : 10px;
        padding-top: 0;
    }

    .close-popover:hover {
        cursor: pointer;
    }
}