@use '../../styles/vars';

#button-loader {
    width: 100%;
    pointer-events: none;
    border-radius: 20px;
    margin-top: 10%;

    .spinner {
        margin-right: 1%;
    }
}

.all-medias {
    width: 95%;
    // margin: 0 auto;
    // padding: 15px 0;
    flex-grow: 1;
    overflow-x: hidden;



    .col-gauche {
        width: 50%;
        text-align: right;
        padding: 0px;
        margin: 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        img,
        video {
            max-width: 100%;
        }
    }

    .presentationCompteVierge {
        .row {
            display: flex;
            max-width: 1200px;
            margin: 0px auto;
            padding: 0;

            h1 {
                font-family: "Poiret One", cursive;
                font-size: 3.5rem;
            }

            .col img {
                max-width: 100%;
            }

            .col {
                padding-left: 0px;
                margin-bottom: 30px;
            }

            h2 {
                font-family: 'Poiret One', cursive;
                font-size: 3rem;
                margin-top: 40px;
            }

            .titre-col {
                color: vars.$primary;
                font-family: 'Sacramento', cursive;
                font-size: 4rem;
                padding: 0px 15px;
            }

            .description-col {
                padding: 0px 15px;
            }

        }
    }

    .col-droite {
        width: 50%;
        text-align: left;
        padding: 0px;
        margin: 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        img,
        video {
            max-width: 100%;
        }
    }

    .all-medias-header {
        position: relative;
        // margin: 10px auto;
        margin-bottom: 40px;
        height: 15%;
        display: flex;
        justify-content: center;

        .allMedias_menu {
            display: flex;
            z-index: 1;
            position: absolute;
            right: -235px;
            top: 10px;
            transform: translate(0px);
            transition: transform 0.5s;

            @media (max-width: 768px) {
                top: 25px;
            }

            .btn.btn-primary {
                height: 35px;
                margin-right: 15px;
                border-radius: 25px;
                padding: 5px 10px;
                // @media (max-width: 576px) {
                //   border-radius: 50%;
                //   width: 35px;
                //   text-align: center;
                // }
            }

            .button-close {
                border: 1px solid vars.$primary;
                margin-right: 15px;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                color: vars.$primary;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .allMedias_menu--open {
            transform: translate(-240px);
            transition: transform 0.5s;
        }

        .actions_list {
            width: 220px;
        }
    }
}

#addAnswerkissModal {
    #answer-textarea {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        textarea {
            padding: 10px;
            resize: none;
            border-color: lightgrey;
            border-radius: 5px;
            outline: none;
        }
    }
}



@media(max-width: 768px) {
    .row {
        margin-bottom: 30px !important;
    }

    .columReverse .col-gauche {
        order: 2;
    }

    .columReverse .col-droite {
        order: 1;
    }

    .col-gauche {
        width: 100% !important;
        text-align: left !important;
    }

    .col-droite {
        width: 100% !important;
    }

    .titre-col {
        color: vars.$primary;
        font-size: 3rem !important;
        padding: 0px 0px !important;
    }

    .description-col {
        padding: 0px 0px !important;
        margin-bottom: 10px !important;
    }
}