@use '../../styles/vars';

.home-container {

    padding-bottom: 60px;

    h1 {
        font-family: "Poiret One", cursive;
        font-size: 3.5rem;
    }
    
    flex-grow: 1;
    margin: 15px;

    .text-rouge, h2 {
        color: vars.$primary;
    }

    h2 {
        font-family: 'Poiret One', cursive;
        font-size: 3rem;
        margin-top: 40px;
    }


    .row {
        display : flex;
        max-width: 1200px;
        margin: 0px auto;
        padding: 0;

        .col img {
            max-width: 100%;
        }
        
        .col-gauche {
            width: 50%;
            text-align: right;
            padding: 0px;
            margin: 0px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            img, video {
                max-width: 100%;
            }
        }
    
        .col-droite {
            width: 50%;
            text-align: left;
            padding: 0px;
            margin: 0px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            img, video {
                max-width: 100%;
            }
        }
    }

    @media(max-width: 768px) {
        .row {
            margin-bottom: 30px !important;
        }

        .columReverse .col-gauche {
            order: 2;
        }
        .columReverse .col-droite {
            order: 1;
        }
        .col-gauche {
            width: 100% !important;
            text-align: left !important;
        }

        .col-droite {
            width: 100% !important;
        }

        .titre-col {
            color: vars.$primary;
            font-size: 3rem !important;
            padding: 0px 0px !important;
        }
    
        .description-col {
            padding: 0px 0px !important;
            margin-bottom: 10px !important;
        }
    }

    .titre-col {
        color: vars.$primary;
        font-family: 'Sacramento', cursive;
        font-size: 4rem;
        padding: 0px 15px;
    }

    .description-col {
        padding: 0px 15px;
    }
}