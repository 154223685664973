.confirmation {
    width         : 95%;
    flex-grow     : 1;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 50px;
    padding       : 10px;

    // justify-content: center;
    p {
        text-align: center;
    }
}