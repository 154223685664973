.lost-password {
    display        : flex;
    justify-content: center;
    flex-grow      : 1;
    margin-top     : 100px;

    form {
        position: relative;

        @media (max-width: 425px) {
            width: 70%;
        }

        @media (min-width: 426px) {
            width: 60%;
        }

        @media (min-width: 576px) {
            width: 50%;
        }

        @media (min-width: 769px) {
            width: 40%;
        }

        @media (min-width: 1025px) {
            width: 30%;
        }

        display       : flex;
        flex-direction: column;

        label {
            width: 100%;
        }

        .buttons {
            margin-top: 10px;

            &>* {
                margin-right: 10px;
            }
        }

        #show-password {
            position        : absolute;
            right           : 5px;
            top             : 40px;
            border          : none;
            background-color: transparent;
        }
    }
}