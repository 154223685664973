@use '../../styles/vars';

@media only screen and (min-width: 768px) {
    .marginMenuLeft {
        padding-left: 230px;
    }
}

.layoutContentMenu {
    position        : fixed;
    top             : 70px;
    height          : calc(100vh - 70px - 35px);
    z-index         : 2 !important;
    background-color: #fff;
    color           : #000;
    border-right    : solid 1px vars.$primary;
    padding         : 1rem 1rem;
}

.layoutContentContent {
    padding: 0 0 50px 0;
}

.layoutContentMenuNavbar {
    width: 215px;

    .nav_link {
        display              : block !important;
        text-decoration      : none;
        text-underline-offset: 3px;
        color                : #000;

        &.selected {
            color      : vars.$primary;
            font-weight: bold;
            font-size  : 1.3rem;
        }
    }

    .boutonAction {
        border-top : solid 1px vars.$primary;
        margin-top : 15px;
        padding-top: 15px;

        button {
            width        : 100%;
            margin-bottom: 10px;
        }
    }

    .menuSingleAlbumStart {
        font-weight: bold;
    }

    .menuSingleAlbumEnd {
        border-top   : solid 1px vars.$primary;
        margin-top   : 15px;
        margin-bottom: 15px;
    }
}

.layoutContentMenuBurgerMenu {
    display: none;
}

@media only screen and (max-width: 768px) {
    .layoutContentMenuNavbar {
        width: 260px;
    }

    .layoutContentMenuBurgerMenu {
        display  : block;
        font-size: 1.5rem;
    }

    .layoutContentMenuNavbarHide {
        .layoutContentMenuNavbar {
            display: none;
        }
    }


    .layoutContentMenu {
        z-index      : 4 !important;
        right        : 0px;
        top          : 0px;
        height       : calc(100vh - 35px);
        border-bottom: solid 1px vars.$primary;
        border-left  : solid 1px vars.$primary;
    }

    .layoutContentMenu.layoutContentMenuNavbarHide {
        height: 67px;
    }
}