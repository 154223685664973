@use '../../styles/vars';

.upload {
    p {
        margin: 15px 0 10px;
    }

    label {
        height          : 35px;
        border          : none;
        border-radius   : 25px;
        padding         : 5px 10px;
        color           : white;
        background-color: vars.$primary;
        margin-bottom   : 15px;

        &:hover {
            cursor: pointer;
        }

        input {
            display: none;
        }

        @media (max-width: 576px) {
            border-radius: 50%;
            width        : 35px;
            text-align   : center;
        }
    }

    button {
        border          : none;
        background-color: transparent;
        padding         : 0;
    }

    .dropzone {
        visibility      : hidden;
        position        : absolute;
        top             : 0;
        left            : 0;
        width           : 100%;
        height          : 100%;
        z-index         : 100;
        background-color: rgba(red, 0.2);
    }

    .input-dropzone {
        display: none;
        z-index: 100;
    }
}

.upload-files-list {
    position: fixed;
    bottom  : 40px;
    left    : 5px;
    z-index : 3;

    .upload-file-item {
        svg {
            padding-top : 2px;
            margin-right: 5px;
        }

        .upload-progress-bar {
            border-radius   : 3px;
            position        : relative;
            height          : 3px;
            background-color: vars.$primary !important;
        }
    }
}