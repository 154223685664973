@use '../../styles/vars';

.row {
    height       : 80%;
    margin-bottom: 5%;

    .col {
        padding-left: 25px;

        .medias {
            width: 100%;

            @media (max-width: 768px) {
                margin-top: 5px;
            }

            .medias-list {
                list-style: none;
                padding   : 0;

                .media-item {
                    display       : inline-block;
                    padding-bottom: 10px;

                    .blocImage {
                        position: relative;

                        img {
                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .btnActionAsset {
                            height          : 30px;
                            position        : absolute;
                            bottom          : 0px;
                            background-color: rgba(255, 255, 255, 0.7);
                            width           : 70px;
                            padding         : 4px;
                            display         : flex;
                            color           : #666;
                            font-size       : 1.2rem;
                            justify-content : space-around;
                            align-items     : center;

                            svg:hover {
                                cursor: pointer;
                            }

                            .FontAwesomeFaComment.alight {
                                color: vars.$primary;
                            }

                            .FontAwesomeFaHeart.alight {
                                color: vars.$primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

#popover-like {
    display       : flex;
    flex-direction: column;

    svg {
        margin-left: auto;
        padding    : 5px;
    }

    .popover-body {
        padding    : 10px;
        padding-top: 0;
    }

    .close-popover:hover {
        cursor: pointer;
    }
}

#comments-counter {
    position        : absolute;
    background-color: transparent !important;
    left            : 10px;
    top             : 7px;
    font-size       : 0.5rem;

    &:hover {
        cursor: pointer
    }

    span {
        width           : 10px;
        height          : 10px;
        position        : absolute;
        display         : flex;
        justify-content : center;
        align-items     : center;
        bottom          : 7px;
        left            : 15px;
        background-color: red;
        border-radius   : 50%;
    }
}