@use'../../styles/vars';

.feedback {
    position        : fixed;
    bottom          : 30px;
    right           : 20px;
    z-index         : 5;
    padding         : 5px 5px;
    border-radius   : 50%;
    border          : 2px solid white;
    background-color: vars.$primary;

    .feedback-button {
        font-size       : 2rem;
        color           : white;
        border          : none;
        background-color: transparent;
    }
}

#feedback-offcanvas {
    background-color: white !important;
    height          : max-content;
    bottom          : 0;
    top             : unset;
    border-radius   : 25px 0 0 0;

    .offcanvas-body {
        padding: 10px;

        label {
            display       : flex;
            flex-direction: column;
            font-size     : 0.9rem;

            textarea {
                margin-top   : 10px;
                border-radius: 10px;
                border       : 1px solid vars.$primary;
                outline      : none;
                resize       : none;
                height       : 150px;
                padding      : 10px;
            }
        }

        .btn {
            margin-top   : 10px;
            border-radius: 20px;
            align-self   : flex-end;
            width        : max-content;
        }
    }
}