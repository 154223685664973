@use '../../../styles/vars';

.all-albums {
    width    : 95%;
    margin   : 0 auto;

    h5 {
        font-family: "Poiret One", cursive;
        font-size   : 2rem;
        color       : vars.$primary;
    }

    .imgFullWidth {
        max-width: 100%;
        height: auto;
    }

    .presentationCompteVierge {
        h1 {
            font-family: "Poiret One", cursive;
            font-size: 3.5rem;
        }
    }

    .rowPresentation {
        max-width: 1200px;
    }


    .all-albums-header {
        position       : relative;
        display        : flex;
        justify-content: center;

        .btn-primary {
            margin-top   : 10px;
            position     : absolute;
            right        : 15px;
            height       : 35px;
            border-radius: 25px;
            padding      : 5px 10px;
        }
    }

    .all-albums-list {
        max-width : 100%;
        margin-top: 35px;
        display   : flex;
        flex-wrap : wrap;

        @media (max-width: 610px) {
            justify-content: center;
        }

        .album-item {
            @media (max-width: 610px) {
                margin: 0 auto 30px;
            }

            position     : relative;
            width        : 260px;
            margin-right : 30px;
            margin-bottom: 30px;
            border-radius: 10px;
            outline      : 1px solid vars.$primary;
            word-break   : break-word;

            img {
                border-radius: 10px 10px 0 0;
            }

            a {
                text-decoration: none;

                .album-title {
                    padding: 10px;
                    color  : black;
                    margin : 0;
                    width  : 90%;
                }

                .album-owner {
                    font-size: 1rem;
                    padding: 5px 10px;
                    font-style: italic;
                    color  : vars.$primary;
                    margin : 0;
                    width  : 100%;
                }
            }

            .album-delete {
                position  : absolute;
                bottom    : 10px;
                right     : 10px;
                text-align: right;
                color     : vars.$primary;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.modal.show {
    width: 100%;

    .modal-dialog {
        max-width: 70% !important;
        margin   : 5rem auto !important;

        @media (max-width: 576px) {
            max-width: 90% !important;
            font-size: 0.9rem;
        }

        .modal-body {
            display       : flex;
            flex-direction: column;
        }

        .closeIcon {
            align-self: flex-end;
            color     : vars.$primary;
            font-size : 1.3rem;

            &:hover {
                cursor: pointer
            }
        }

        .newAlbum-title--hide {
            display: none;
        }

        label {
            width: 100%;
        }

        .medias-selection {
            margin-top: 10px;
        }

        .all-medias {
            max-width     : 100%;
            margin        : 10px auto;
            max-height    : 50vh;
            display       : flex;
            flex-direction: row;
            flex-wrap     : wrap;
            overflow-y    : auto;

            .media-item {
                margin-right : 10px;
                margin-bottom: 10px;
                position     : relative;

                .selected {
                    border-radius : 5px;
                    outline       : 2.5px solid vars.$primary;
                    outline-offset: -2.5px;
                }

                .selected~.overlay {
                    position        : absolute;
                    top             : 0px;
                    width           : 260px;
                    height          : 180px;
                    border-radius   : 5px;
                    background-color: rgba(vars.$primary, 0.2);
                    z-index         : 2;
                }
            }
        }

        .modal-footer {
            border-top: none;
        }
    }
}

.txt-info-share-with-me {
    color: #999;
}

@media(max-width: 768px) {
    .row {
        margin-bottom: 30px !important;
    }

    .columReverse .col-gauche {
        order: 2;
    }
    .columReverse .col-droite {
        order: 1;
    }
    .col-gauche {
        width: 100% !important;
        text-align: left !important;
    }

    .col-droite {
        width: 100% !important;
    }

    .titre-col {
        color: vars.$primary;
        font-size: 3rem !important;
        padding: 0px 0px !important;
    }

    .description-col {
        padding: 0px 0px !important;
        margin-bottom: 10px !important;
    }
}