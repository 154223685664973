.profile {
    width         : 95%;
    margin        : 0 auto;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    flex-grow     : 1;

    .navbar {
        align-self: flex-start;
    }

    #profile {
        width: 90%;

        @media (min-width: 425px) {
            width: 80%;
        }

        @media (min-width: 768px) {
            width: 70%;
        }

        @media (min-width: 1024px) {
            width: 50%;
        }

        label {
            width: 100%;
        }

        .buttons {
            margin-top: 10px;

            &>* {
                margin-right: 10px;
            }
        }
    }
}